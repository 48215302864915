import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";

const wrapper = css`
  width: 90%;
  margin: 0 auto;
`;

const sectionContainer = css`
  border-bottom: ${color.gray} 2px solid;
  padding: 40px 0;
  :last-of-type {
    border-bottom: none;
  }
  ${mediaQuery[smartPhone]} {
    border-bottom: ${color.gray} 1px solid;
    padding: 24px 0;
  }
`;

const question = css`
  display: flex;
  font-size: 18px;
  align-items: center;
  line-height: 150%;
  color: ${color.black};
  ${mediaQuery[smartPhone]} {
    font-size: 12px;
    line-height: 17px;
  }
`;

const questionCircle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 3px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 50%;
  ${mediaQuery[smartPhone]} {
    width: 35px;
    height: 35px;
  }
`;

const questionMark = css`
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.subtitleSize};
  color: ${color.black};
  font-weight: bold;
  line-height: 34px;
  ${mediaQuery[smartPhone]} {
    font-size: 12px;
    line-height: 17px;
  }
`

const questionText = css`
  display: block;
  padding-left: 60px;
  ${mediaQuery[smartPhone]} {
    padding-left: 16px;
    width: 85%;
  }
`

const answer = css`
  display: flex;
  font-size: 18px;
  align-items: center;
  line-height: 150%;
  color: ${color.black};
  padding-top: 24px;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    align-items: start;
  }
`;

const answerLast = css`
  display: flex;
  font-size: 18px;
  align-items: center;
  line-height: 150%;
  color: ${color.black};
  padding-top: 24px;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
  }
`

const answerCircle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: ${font.subtitleSize};
  font-weight: bold;
  line-height: 34px;
  background-color: ${color.mainL};
  border: 3px solid ${color.mainL};
  box-sizing: border-box;
  border-radius: 50%;
  ${mediaQuery[smartPhone]} {
    width: 35px;
    height: 35px;
    font-size: ${font.ButtonTextSize};
  }
`;

const answerMark = css`
  font-family: 'Noto Serif JP', serif;
  color: ${color.white};
`

const answerText = css`
  display: block;
  width: 100%;
  padding-left: 60px;
  ${mediaQuery[smartPhone]} {
    padding-left: 16px;
    width: 85%;
  }
`

export const QuestionAndAnswerItems = () => {
  return (
    <div css={wrapper}>
      <div css={sectionContainer}>
        <div css={question}>
          <div css={questionCircle}><p css={questionMark}>Q</p></div>
          <p css={questionText}>開業準備期間はどれくらい必要ですか？また、どのような流れになりますか？</p>
        </div>
        <div css={answer}>
          <div css={answerCircle}><p css={answerMark}>A</p></div>
          <p css={answerText}>
            開業までの期間は、物件が決まってから５〜６ヶ月くらいでお考え頂ければと思います。<br />
            もちろん、勤務医を続けながら開業を迎えることも可能です。
          </p>
        </div>
      </div>
      <div css={sectionContainer}>
        <div css={question}>
          <div css={questionCircle}><p css={questionMark}>Q</p></div>
          <p css={questionText}>奈良県医師協同組合に依頼するメリットは？</p>
        </div>
        <div css={answer}>
          <div css={answerCircle}><p css={answerMark}>A</p></div>
          <p css={answerText}>
          当組合は、組合員である先生方によって運営されている事業協同組合です。<br />
          当組合では非営利を軸としたスケールメリットを活かした共同事業を行っており、医療機器・消耗品を安くご提供し、また医業経営に必要な保険の団体割引の適用や、先生やクリニックスタッフの福利厚生サービスをご提供しております。<br />
          また、税理士・社労士・ファイナンシャルプランナー等の専門家と連携しておりますので、各種ご相談にも対応させていただきます。
          </p>
        </div>
      </div>
      <div css={sectionContainer}>
        <div css={question}>
          <div css={questionCircle}><p css={questionMark}>Q</p></div>
          <p css={questionText}>新規開業と医院継承はどちらの方がコストを抑えられますか？</p>
        </div>
        <div css={answer}>
          <div css={answerCircle}><p css={answerMark}>A</p></div>
          <p css={answerText}>
          医院継承の場合は、引き継ぐクリニックの条件にもよりますが、内装工事費や医療機器購入費、職員採用費等がかからないため、新規開業よりイニシャルコストを抑えられるケースが多いです。
          </p>
        </div>
      </div>
      <div css={sectionContainer}>
        <div css={question}>
          <div css={questionCircle}><p css={questionMark}>Q</p></div>
          <p css={questionText}>何年か先に開業したいと思っているがそんな相談でも良い？</p>
        </div>
        <div css={answerLast}>
          <div css={answerCircle}><p css={answerMark}>A</p></div>
          <p css={answerText}>
          どんなことでも結構です、ご相談ください。
          </p>
        </div>
      </div>
    </div>
  );
};
