import React, { useState, useEffect } from "react";
import { Layout } from "../components/Layout";
import { css } from '@emotion/core'
import { Head } from '../components/Head'
import { font, color, mediaQuery, smartPhone } from "../constants/styles";
import { QuestionAndAnswerItems } from "../components/QuestionAndAnswerItems";

const wrapper = css`
  width: 100%;
  padding-bottom: 211px;
  ${mediaQuery[smartPhone]} {
    padding-bottom: 85px;
  }
`

const titleAria = css`
  text-align: center;
  padding: 95px 0 20px 0;
  margin: 0 auto;
  ${mediaQuery[smartPhone]} {
    padding: 109px 0 10px 0;
  }
`

const mainTitle = css`
  font-family: 'Noto Serif JP', serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #8F5E5E;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    line-height: 17px;
  }
`

const subTitle = css`
  display: block;
  padding: 24px 0;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.NomaltitleSize};
  color: #5F5450;
  line-height: 52px;
  ${mediaQuery[smartPhone]} {
    font-size: 18px;
    padding: 12px 0;
    line-height: 26px;
  }
`

const border = css`
  width: 80px;
  height: 2px;
  margin: 0 auto;
  background-color: ${color.gray};
  ${mediaQuery[smartPhone]} {
    width: 60px;
    height: 1px;
  }
`

export default function QuestionAndAnswers({ data, location }) {
  const siteMetadata = data?.site?.siteMetadata
  const [pathname, setPathname] = useState("");
  useEffect(() => {
    setPathname(location.pathname);
  }, []);
  return (
    <Layout location={pathname}>
      <Head props={siteMetadata} />
      <div css={wrapper}>
        <div css={titleAria}>
          <h3 css={mainTitle}>Q&A</h3>
          <p css={subTitle}>よくあるご質問</p>
          <div css={border}></div>
        </div>
        <QuestionAndAnswerItems />
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title,
        siteUrl,
        description
      }
    }
    allMicrocmsNews {
      nodes {
        daytime
        newsImage1 {
          url
        }
        newsImage2 {
          url
        }
        newsImage3 {
          url
        }
        newsImage4 {
          url
        }
        newsText
        newsTitle
        id
      }
    }
  }
`
